import { gql } from '@apollo/client';

export const deleteBookmarkQuery = gql`
  mutation DeleteBookmark($itemId: ID!) {
    deleteBookmark(itemId: $itemId) {
      bookmark {
        itemId
      }
      errors {
        message
        path
      }
    }
  }
`;

export const createBookmarkQuery = gql`
  mutation CreateBookmark($itemId: ID!) {
    createBookmark(itemId: $itemId) {
      bookmark {
        itemId
      }
      errors {
        message
        path
      }
    }
  }
`;

export const deleteCompanyQuery = gql`
  mutation SignOut {
    signOut {
      profile {
        ... on CompanyProfile {
          id
        }
      }
      errors {
        message
      }
    }
  }
`;

export const sendAccessToken = gql`
  mutation Auth($accessToken: String!) {
    signIn(input: { accessToken: $accessToken }) {
      profile {
        ... on CompanyProfile {
          id
          name
          phone
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

export const createReportQuery = gql`
  mutation CreateReport(
    $itemId: ID!
    $reportReasonId: ID!
    $description: String
  ) {
    createReport(
      input: {
        itemId: $itemId
        reportReasonId: $reportReasonId
        description: $description
      }
    ) {
      report {
        id
      }
      errors {
        code
        message
        path
      }
    }
  }
`;

export const createPaymentQuery = gql`
  mutation CreatePayment(
    $amount: Float
    $paidServiceId: ID!
    $paidServiceOptionId: ID!
    $paymentMethodId: ID!
    $targetId: ID!
    $platform: PaymentCreatePlatformEnum
  ) {
    createPayment(
      input: {
        amount: $amount
        paidServiceId: $paidServiceId
        paidServiceOptionId: $paidServiceOptionId
        paymentMethodId: $paymentMethodId
        targetId: $targetId
        platform: $platform
      }
    ) {
      status
      redirectUrl
      errors {
        code
        message
        path
      }
    }
  }
`;

export const createItemQuery = gql`
  mutation CreateItem(
    $area: ItemCreateAreaInput!
    $buildingType: ItemCreateBuildingTypeEnum
    $categoryId: ID!
    $contact: ItemCreateContactInput!
    $description: String
    $floor: ItemCreateFloorInput
    $hasBillOfSale: Boolean
    $hasMortgage: Boolean
    $hasRepair: Boolean
    $isLeased: Boolean!
    $location: ItemCreateLocationInput!
    $paidDaily: Boolean
    $photoIds: [ID!]!
    $price: Int!
    $rooms: Int
  ) {
    createItem(
      input: {
        area: $area
        buildingType: $buildingType
        categoryId: $categoryId
        contact: $contact
        description: $description
        floor: $floor
        hasBillOfSale: $hasBillOfSale
        hasMortgage: $hasMortgage
        hasRepair: $hasRepair
        isLeased: $isLeased
        location: $location
        paidDaily: $paidDaily
        photoIds: $photoIds
        price: $price
        rooms: $rooms
      }
    ) {
      status
      item {
        id
      }
      errors {
        code
        message
        path
      }
    }
  }
`;

export const updateItemQuery = gql`
  mutation UpdateItem(
    $area: ItemUpdateAreaInput
    $contactName: String
    $description: String
    $floor: ItemUpdateFloorInput
    $hasBillOfSale: Boolean
    $hasMortgage: Boolean
    $hasRepair: Boolean
    $itemId: ID!
    $paidDaily: Boolean
    $photoIds: [ID!]
    $price: Int
    $rooms: Int
  ) {
    updateItem(
      input: {
        area: $area
        contactName: $contactName
        description: $description
        floor: $floor
        hasBillOfSale: $hasBillOfSale
        hasMortgage: $hasMortgage
        hasRepair: $hasRepair
        itemId: $itemId
        paidDaily: $paidDaily
        photoIds: $photoIds
        price: $price
        rooms: $rooms
      }
    ) {
      status
      item {
        id
      }
      lockedUntil
      errors {
        code
        message
        path
      }
    }
  }
`;

export const payForItemQuery = gql`
  mutation PayForItem($itemId: ID!) {
    payForItem(input: { itemId: $itemId }) {
      item {
        state
      }
      errors {
        code
        message
        path
      }
    }
  }
`;

export const prolongItemQuery = gql`
  mutation ProlongItem($itemId: ID!) {
    prolongItem(input: { itemId: $itemId }) {
      item {
        state
      }
      errors {
        code
        message
        path
      }
    }
  }
`;

export const sendAnalyticsDataQuery = gql`
  mutation CreateAnalyticLog(
    $targetId: ID!
    $sourceLink: String
    $triggerButton: String
    $phones: [String!]
    $targetType: AnalyticLogTargetTypeEnum!
    $eventType: AnalyticLogEventTypeEnum!
    $source: AnalyticLogSourceEnum!
  ) {
    createAnalyticLog(
      input: {
        targetId: $targetId
        targetType: $targetType
        sourceLink: $sourceLink
        triggerButton: $triggerButton
        phones: $phones
        eventType: $eventType
        source: $source
      }
    ) {
      errors {
        code
        message
        path
      }
    }
  }
`;

export const editProfileQuery = gql`
  mutation EditProfile(
    $name: String
    $email: String!
    $alternativePhone: String
  ) {
    updateProfile(
      input: { name: $name, email: $email, alternativePhone: $alternativePhone }
    ) {
      errors {
        code
        message
        path
      }
      status
      profile {
        ... on CompanyProfile {
          name
          email
          alternativePhone
        }
      }
    }
  }
`;

export const changeProfilePasswordQuery = gql`
  mutation changeProfilePassword(
    $currentPassword: String!
    $newPassword: String!
  ) {
    changeProfilePassword(
      input: { currentPassword: $currentPassword, newPassword: $newPassword }
    ) {
      errors {
        code
        message
        path
      }
      status
    }
  }
`;

export const createInvoiceQuery = gql`
  mutation createInvoiceForPackage(
    $activationType: InvoiceCreateForPackageActivationTypeEnum!
    $name: String!
    $period: Int!
  ) {
    createInvoiceForPackage(
      input: { activationType: $activationType, name: $name, period: $period }
    ) {
      errors {
        code
        message
        path
      }
      invoice {
        id
        status
        amount
        paidServices {
          id
          activeUntil
          title
          targetType
          options {
            id
            name
            price
            priceWithDiscount(paymentMethodId: "wallet")
          }
          paymentMethods {
            id
            balance
            name
          }
        }
      }
    }
  }
`;
