import { gql } from '@apollo/client';
import genericFields from './genericFields';
import { profileItemStatusCounts } from './profileItemStatusCounts';

export const companyNameQuery = gql`
  query CompanyNameQuery {
    profile {
      ... on CompanyProfile {
        name
        id
        phone
      }
    }
  }
`;

export const userDataQuery = gql`
  query UserDataQuery {
    profile {
      ... on CompanyProfile {
        name
        id
        phone
        email
        contactName
        targetType
      }
    }
  }
`;

export const profileBalanceQuery = gql`
  query CompanyNameQuery {
    profile {
      ... on CompanyProfile {
        paidItemsBalance
      }
    }
  }
`;

export const getProfileDataQuery = gql`
  query GetProfileQuery {
    profile {
      ... on CompanyProfile {
        id
        name
        email
        phone
        alternativePhone
        hasUnpaidInvoice
        balance {
          item {
            free
            nextFreeAt
            paid
          }
          personalAccount {
            bonus
            cash
            pack
            total
          }
        }
        isBusiness
        business {
          ... on Agency {
            id
            name
          }
          ... on Residence {
            id
            name
          }
        }
        paidServices {
          id
          activeUntil
          title
          targetType
          options {
            id
            name
            price
            priceWithDiscount(paymentMethodId: "wallet")
          }
          paymentMethods {
            id
            balance
            name
          }
        }
      }
    }
  }
`;

export const getProfileStateItemsQuery = ({ first, state }) => {
  const name = `GetProfileStateItemsQuery_${first}_${state}`;
  return gql`
    query ${name} (
    $first: Int, $after: String, $state: ProfileItemTabStatesEnum!
    ) {
    profile {
      ... on CompanyProfile {
        id
        ${profileItemStatusCounts}
        items (
        state: $state, first: $first, after: $after
        ) {
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              state
              ${genericFields}
              photos(limit: 4) {
                thumbnail
                f460x345
                large
              }
            }
          }
        }
      }
    }
  }
  `;
};

export const getProfileActivePackagesDataQuery = gql`
  query GetProfileActivePackagesQuery {
    invoices(scope: ACTIVE) {
      edges {
        node {
          id
          amount
          additionalConditions
          package {
            discount
            name
            paidAdsCount
            walletAmount
          }
          expiresAt
          startsAt
          status
          vatAmount
        }
      }
    }
  }
`;

export const getProfileInvoicesQuery = ({ first, scope = '' }) => {
  const name = `GetProfileInvoicesQuery_${first}_${scope}`;
  return gql`
    query ${name} ($first: Int, $scope: InvoiceScopeEnum, $cursor: String) {
      invoices(first: $first scope: $scope after: $cursor) {
        totalCount
        pageInfo {
            hasNextPage
            endCursor
          }
        edges {
          node {
            id
            amount
            additionalConditions
            package {
              discount
              name
              paidAdsCount
              walletAmount
            }
            payment {
              paymentMethodName
              createdAt
            }
            expiresAt
            startsAt
            status
            vatAmount
            printUrl
          }
        }
      }
    }
  `;
};

export const getProfileCurrentInvoiceQuery = ({ id }) => {
  const name = `GetCurrentInvoiceQuery_${id}`;
  return gql`
    query ${name}($id: ID!) {
      invoice(id: $id) {
        amount
        expiresAt
        id
        paidAt
        startsAt
        status
        vatAmount
        payment {
          paymentMethodName
          createdAt
        }
        package {
          paidAdsCount
          discount
          name
        }
      }
    }
  `;
};

export const getProfileTariffsDataQuery = gql`
  query GetProfileTariffsQuery {
    packages {
      name
      discount
      paidAdsCount
      price
      walletAmount
      periods {
        price
        value
      }
    }
  }
`;

export const getProfilePaidServiceOptionsDataQuery = gql`
  query GetProfilePaidServiceOptionsQuery {
    bump: paidServiceOptions(paidServiceId: "bump") {
      ...PaidServiceFields
    }
    vip: paidServiceOptions(paidServiceId: "vip") {
      ...PaidServiceFields
    }
    feature: paidServiceOptions(paidServiceId: "feature") {
      ...PaidServiceFields
    }
  }

  fragment PaidServiceFields on PaidServiceOption {
    id
    name
    price
    priceWithDiscount(paymentMethodId: "wallet")
  }
`;

export const getProfilePaymentHistoryQuery = ({ first }) => {
  const name = `GetProfilePaymentHistoryQuery_${first}`;
  return gql`
    query ${name} ($first: Int, $cursor: String) {
     paymentHistory(first: $first after: $cursor) {
        totalCount
        edges {
          node {
            amount
            total
            createdAt
            discount
            id
            item {
              id
              title
              paidDaily
              isLeased
              price {
                value
                currency
              }
              photos (limit: 1) {
                full
                f660x496
              }
            }
            paidServiceOptionName
            paymentMethodName
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `;
};

export const getProfileAccountBalanceHistoryQuery = ({ first }) => {
  const name = `GetProfileAccountBalanceHistoryQuery_${first}`;
  return gql`
    query ${name} ($first: Int, $cursor: String) {
      personalAccountBalanceHistory(first: $first after: $cursor) {
        totalCount
        edges {
          node {
            id
            total
            createdAt
            payment {
              id
              amount
              createdAt
              discount
              paidServiceOptionName
              paymentMethodName
              item {
                id
                title
                paidDaily
                isLeased
                price {
                  value
                  currency
                }
                photos (limit: 1) {
                  full
                  f660x496
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `;
};

export const getProfilePaidAdsTransactionsQuery = ({ first }) => {
  const name = `GetProfilePaidAdsTransactionsQuery_${first}`;
  return gql`
    query ${name} ($first: Int, $cursor: String) {
      itemBalanceHistory(first: $first after: $cursor) {
        totalCount
        edges {
          node {
            id
            createdAt
            total
            source {
              ... on Item {
                id
              }
              ... on Payment {
                id
                amount
                createdAt
                paidServiceOptionName
                paymentMethodName
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `;
};

const temp = 'just for eslint';

export default temp;
